<template>
  <div>
    <div
      class="event-detail"
      v-if="EventDetailData && Object.keys(EventDetailData).length"
    >
      <div class="content">
        <div class="event-detail__title" v-html="EventDetailData.name"></div>
      </div>
      <Breadcrumbs
        :color="'#1B98D5'"
        :pages="[
          {
            name: 'Мероприятия',
            link: {
              name: 'Events',
            },
          },
          {
            name: EventDetailData.name,
          },
        ]"
      />
      <div class="content first-banner__content">
        <div class="first-banner">
          <picture>
            <source
              :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/skv-event/banner.jpg`"
              media="(max-width: 767px)"
            />
            <source
              :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/skv-event/banner.jpg`"
              media="(max-width: 1219px)"
            />
            <source
              :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/skv-event/banner.jpg`"
              media="(min-width: 1220px)"
            />
            <img
              class="first-banner__image"
              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/skv-event/banner.jpg`"
            />
          </picture>
        </div>
      </div>
      <div class="content">
        <div class="event-detail__about-description mt-8">
          Приглашаем Вас принять участие в мероприятии посвященном проблемам
          диагностики и терапии системной красной волчанки. Ведущие эксперты -
          ревматологи Санкт-Петербурга поделятся своим бесценным опытом и
          знаниями в этой области.
        </div>
        <div class="event-detail__timer mt-8">
          <div class="event-detail__timer-title">
            {{
              !Object.keys(timerResult).length
                ? "Мероприятие началось"
                : timerResult.months
                ? "Подключение к трансляции:"
                : "Мероприятие начнется через:"
            }}
          </div>
          <div class="event-detail__timer-date" v-if="timerResult.months">
            {{ $m(EventDetailData.date).format("DD MMMM") }}
            в
            {{
              $m
                .utc(EventDetailData.date)
                .utcOffset(+3)
                .subtract(10, "minutes")
                .format("HH:mm")
            }}
            (МСК)
          </div>
          <div
            v-else-if="Object.keys(timerResult).length"
            class="event-detail__timer-row"
          >
            <!-- <div class="event-detail__timer-item" v-if="timerResult.months">
              <div class="timer__value">{{ timerResult.months }}</div>
              <div class="timer__text">Месяцев</div>
            </div> -->
            <div class="event-detail__timer-item" v-if="timerResult.days > 0">
              <div class="timer__value">{{ timerResult.days }}</div>
              <div class="timer__text">
                {{  helpers.getNoun(timerResult.days, "день", "дня", "дней") }}
              </div>
            </div>
            <div class="event-detail__timer-item" v-if="timerResult.hours > 0">
              <div class="timer__value">{{ timerResult.hours }}</div>
              <div class="timer__text">
                {{  helpers.getNoun(timerResult.hours, "час", "часа", "часов") }}
              </div>
            </div>
            <div
              class="event-detail__timer-item"
              v-if="timerResult.minutes > 0"
            >
              <div class="timer__value">{{ timerResult.minutes }}</div>
              <div class="timer__text">
                {{  helpers.getNoun(timerResult.minutes, "минуту", "минуты", "минут") }}
              </div>
            </div>
            <div
              class="event-detail__timer-item"
              v-if="timerResult.seconds > 0"
            >
              <div class="timer__value">{{ timerResult.seconds }}</div>
              <div class="timer__text">
                {{
                   helpers.getNoun(timerResult.seconds, "секунду", "секунды", "секунд")
                }}
              </div>
            </div>
          </div>
          <a
            :href="$root.ssoLinks.login"
            v-if="
              !Object.keys(timerResult).length &&
              (!user || !Object.keys(user).length)
            "
            class="event-detail__translation button button_white-blue px-6"
          >
            Зарегистрироваться
          </a>
          <div
            v-else-if="
              !Object.keys(timerResult).length &&
              user &&
              Object.keys(user).length
            "
            @click="openIframe()"
            class="event-detail__translation button button_white-blue px-6"
          >
            Смотреть
          </div>
        </div>
        <div class="event-detail__date-info">
          <div class="event-detail__date-text">
            <div class="event-detail__date-item" v-if="EventDetailData.date">
              <div class="event-detail__date-item-title">Дата и время</div>
              <div class="event-detail__date-item-value">
                {{ $m(EventDetailData.date).format("DD MMMM") }},
                {{
                  $m
                    .utc(EventDetailData.date)
                    .utcOffset(+3)
                    .format("HH:mm")
                }}
                (Мск)
              </div>
            </div>
            <div class="event-detail__date-item" v-if="EventDetailData.place">
              <div class="event-detail__date-item-title">Место</div>
              <div class="event-detail__date-item-value">
                {{ EventDetailData.place }}
              </div>
            </div>
            <div class="event-detail__date-item ml-auto ml-sm-0 ml-md-0 mr-0">
              <a
                :href="$root.ssoLinks.login"
                v-if="
                  Object.keys(timerResult).length &&
                  (!user || !Object.keys(user).length)
                "
                class="event-detail__translation button button_dark-blue px-6"
              >
                Зарегистрироваться
              </a>
            </div>
          </div>
        </div>

        <DefaultSlider
          :color="'#2186AF'"
          :title="'Наши эксперты'"
          :settings="settingsSlider"
          id="experts"
        >
          <template #items>
            <div
              class="slide-lector"
              v-for="(lector, ind) in lectors"
              :key="ind"
            >
              <div class="slide-lector__image-container">
                <img class="slide-lector__image" :src="lector.image" />
              </div>
              <div class="slide-lector__tag" v-html="lector.tag"></div>
              <div class="slide-lector__name" v-html="lector.name"></div>
              <div
                class="slide-lector__description"
                v-html="lector.description"
              ></div>
            </div>
          </template>
        </DefaultSlider>

        <div class="event-detail__about-title">Расписание и лекторы</div>
        <div class="event-detail__main-info mt-16" id="programm">
          <div class="event-detail__about-title">
            Программа на 6 февраля, 16:00 – 19:10
          </div>
          <div class="event-detail__tip">(указано московское время)</div>
          <div class="mt-8">
            <div v-html="programmHTML(program)"></div>
          </div>
        </div>
        <div class="event-detail__usefull-materials">
          <div class="event-detail__usefull-materials-title">
            Список сокращений
          </div>
          <div class="event-detail__usefull-materials-description">
            НИИ – научно-исследовательский институт, 
            <br /><br />
            СЗГМУ – Северо-западный государственный медицинский университет,
            <br /><br />
            АРР – Ассоциация ревматологов России,
            <br /><br />
            РАН – Российкая Академия наук,
            <br /><br />
            СКВ – системная красная волчанка,
            <br /><br />
            ЭГПА - эозинофильный гранулематоз с полиангиитом
          </div>
        </div>
      </div>
    </div>
    <Facecast
      :slug="$route.meta.slug || 'skvevent'"
      @closeFrame="openFrame = false"
      :open="openFrame"
    />
    <PopupEnd
      :eventName="'skvevent'"
      :backUrl="{ name: 'SkvEvent' }"
      @close="closePopup"
      v-if="popup"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Breadcrumbs from "../../components/pageComponents/Breadcrumbs.vue";
import InfoDrop from "../../components/pageComponents/InfoDrop.vue";
import DefaultSlider from "@/components/pageComponents/DefaultSlider.vue";
import PopupEnd from "@/components/pageComponents/PopUpEventEnd.vue";
import Facecast from "@/components/pageComponents/Facecast.vue";

export default {
  metaInfo() {
    if (this.EventDetailData.name) {
      return {
        title:
          "Az-most - " + this.EventDetailData.name.replace(/<[^>]*>?/gm, ""),
      };
    } else {
      return { title: "Az-most - " + this.EventDetailData.name };
    }
  },
  name: "EventDetail",
  components: { Breadcrumbs, InfoDrop, DefaultSlider, PopupEnd, Facecast },
  data: () => ({
    popup: false,
    openFrame: false,
    timerResult: { id: 0 },
    timerResultEnd: {},
    interval: null,
    settingsSlider: {
      slidesToShow: 4,
      draggable: false,
      infinite: true,
      responsive: [
        {
          breakpoint: 1220,
          settings: {
            slidesToShow: 2,
            infinite: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            infinite: true,
            variableWidth: false,
          },
        },
      ],
    },
  }),
  computed: {
    ...mapGetters(["EventDetail", "user", "IframeAccess"]),
    lectors() {
      return [
        {
          image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/skv-event/mazurov.jpg`,
          tag: "Спикер",
          name: "Мазуров Вадим Иванович",
          description:
            "Директор НИИ ревматологии, заведующий кафедрой терапии, ревматологии, экспертизы временной нетрудоспособности и качества медицинской помощи им.Э.Э.Эйхвальда СЗГМУ им. И.И.Мечникова. Первый вице-президент АРР, главный внештатный специалист ревматолог комитета по здравоохранению Санкт-Петербурга. Заслуженный деятель науки РФ, д.м.н., профессор, академик РАН.",
        },
        {
          image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/skv-event/leyneman.jpg`,
          tag: "Спикер",
          name: "Лейнеман Яна Андреевна",
          description:
            "Ассистент кафедры терапии, ревматологии, экспертизы временной нетрудоспособности и качества медицинской помощи им.Э.Э.Эйхвальда СЗГМУ им.И.И.Мечникова. к.м.н.",
        },
        {
          image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/skv-event/trofimov.jpg`,
          tag: "Спикер",
          name: "Трофимов Евгений Александрович",
          description:
            "Заместитель директора по науке, профессор кафедры терапии, ревматологии, экспертизы временной нетрудоспособности и качества медицинской помощи им. Э.Э. Эйхвальда СЗГМУ им. И.И. Мечникова, д.м.н.",
        },
        {
          image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/skv-event/samigullina.jpg`,
          tag: "Спикер",
          name: "Самигуллина Рузана Рамиловна",
          description:
            "Врач-ревматолог, руководитель центра терапии генно-инженерными биологическими препаратами клиники им.Э.Э.Эйхвальда, ассистент кафедры терапии, ревматологии, экспертизы временной нетрудоспособности и качества медицинской помощи им. Э.Э.Эйхвальда СЗГМУ им. И.И.Мечникова.",
        },
        {
          image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/skv-event/ilivanova.jpg`,
          tag: "Спикер",
          name: "Иливанова Елена Павловна",
          description:
            "Заведующая ревматологическим отделением ГБУЗ «Ленинградская областная клиническая больница», главный внештатный специалист ревматолог Ленинградской области",
        },
        {
          image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/skv-event/shostak.jpg`,
          tag: "Спикер",
          name: "Шостак Михаил Степанович",
          description:
            "к.м.н., доцент кафедры, заведующий отделением ревматологии Кафедра терапии, ревматологии, экспертизы временной нетрудоспособности и качества медицинской помощи им. Э.Э.Эйхвальда",
        },
        {
          image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/skv-event/chudinov.jpg`,
          tag: "Спикер",
          name: "Чудинов Антон Леонидович",
          description:
            "Ассистент кафедры терапии, ревматологии, экспертизы временной нетрудоспособности и качества медицинской помощи им. Э.Э. Эйхвальда СЗГМУ им. И.И. Мечникова, заведующий отделением Клинической ревматологической больницы №25, к.м.н.",
        },
      ];
    },
    EventDetailData() {
      return {
        lector: [],
        types: [
          {
            id: 1,
            name: "Онлайн",
            code: "online",
          },
        ],
        name: "Актуальные вопросы ведения пациентов с системной красной волчанкой",

        date: this.$route.meta.date || "2025-02-06T16:00:30+03:00",
        date_end: "2025-02-06T19:10:31+03:00",
        place: "Онлайн",
        webinar_id: "sj0f2EEiM1",
        room_id: "671a42e4bc6f3b8417f474c8",
      };
    },
    program() {
      return [
        {
          time: "16:00 – 16:10",
          title: "Регистрация",
          icon: "coffee.svg",
        },
        {
          time: "16:10 – 16:30",
          title: "Лекция «Иммунопатогенез, клиника и диагностика СКВ»",
          icon: "person.svg",
          description: `
              Мазуров Вадим Иванович`,
        },
        {
          time: "16:30 – 16:50",
          title:
            "Лекция «Современные аспекты терапии СКВ. Акцент на международных и отечественных рекомендациях»",
          icon: "person.svg",
          description: `
              Лейнеман Яна Андреевна`,
        },
        {
          time: "16:50 – 17:10",
          title:
            "Лекция «Вопросы коморбидности при СКВ. Распространенность СКВ у пациентов с репродуктивными проблемами»",
          icon: "person.svg",
          description: `
              Трофимов Евгений Александрович`,
        },
        {
          time: "17:10 – 17:30",
          title:
            "Лекция «Рандомизированные клинические исследования по лечению СКВ: результаты зарубежных и российских исследований»",
          icon: "person.svg",
          description: `
              Самигуллина Рузана Рамиловна`,
        },
        {
          time: "17:30 – 17:40",
          title: "Перерыв",
          icon: "coffee.svg",
        },
        {
          time: "17:40 – 18:00",
          title:
            "Лекция «Эффективность и безопасность применения ГИБП в лечении больных СКВ в Ленинградской области»",
          icon: "person.svg",
          description: `
              Иливанова Елена Павловна`,
        },
        {
          time: "18:00 – 18:20",
          title:
            "Лекция «Эозинофильный гранулематоз с полиангиитом, вопросы патогенеза и диагностики».",
          icon: "person.svg",
          description: `
              Шостак Михаил Степанович`,
        },
        {
          time: "18:20 – 18:40",
          title: "Лекция «Современные возможности терапии ЭГПА»",
          icon: "person.svg",
          description: `
              Чудинов Антон Леонидович`,
        },
        {
          time: "18:40 – 19:10",
          title: "Перерыв",
          icon: "coffee.svg",
        },
      ];
    },
  },
  methods: {
    programmHTML(items) {
      let html = ``;
      let block = ``;
      for (let item of items) {
        block += `
              <div class="hobl__program-item">
                <div class="hobl__program-time">${item.time}</div>
                <div class="d-flex align-start hobl__program-info">
                  <img class="mr-4" src="https://storage.yandexcloud.net/${
                    this.$root.backetName
                  }/media/content/public/assets/img/skv-event/${item.icon}"  />
                  <div class="hobl__program-info my-auto">
                    <div class="hobl__program-title">
                    ${item.title}
                    </div>
                    <div style="${
                      item.description ? "" : "display: none"
                    }" class="hobl__program-description">
                      ${item.description}
                    </div>
                  </div>
                </div>
              </div>
            </div>`;
      }
      html = `<div class="hobl__program mb-8">${block}</div>`;
      return html;
    },
    timer() {
      let date = new Date();
      const dateEnd = this.$m
        .utc(this.EventDetailData.date)
        .utcOffset(+3)
        .subtract(10, "minutes");
      const today = this.$m.utc(date).utcOffset(+3);
      const diff = today.diff(dateEnd, "seconds");
      this.$set(this, "timerResult", {});
      if (diff > 0) return;
      let months = Math.abs(diff) / 2592000;
      let fullMonths = Math.floor(months);
      let days = (months - Math.floor(months)) * 30;
      let fullDays = Math.floor(days);
      let hours = (days - Math.floor(days)) * 24;
      let fullHours = Math.floor(hours);
      let minutes = (hours - Math.floor(hours)) * 60;
      let fullMinutes = Math.floor(minutes);
      let seconds = (minutes - Math.floor(minutes)) * 60;
      let fullSeconds = Math.round(seconds);
      this.$set(this, "timerResult", {});
      if (fullMonths) {
        this.$set(this.timerResult, "months", fullMonths);
      }
      if (fullDays) {
        this.$set(this.timerResult, "days", fullDays);
      }
      if (fullHours) {
        this.$set(this.timerResult, "hours", fullHours);
      }
      if (fullMinutes) {
        this.$set(this.timerResult, "minutes", fullMinutes);
      }
      if (fullSeconds) {
        this.$set(this.timerResult, "seconds", fullSeconds);
      }
    },
    async getCalendar(id) {
      const url = await this.fetchEventsCalendar(id);
      let a = document.createElement("a");
      a.style.display = "none";
      document.body.appendChild(a);
      a.href = url;
      a.download = "event.ics";
      a.click();
      setTimeout(async () => {
        window.URL.revokeObjectURL(url);
      }, 1000);
      a.remove();
    },
    
    openInner() {
      // if (typeof ym !== "undefined") {
      //   ym(91468266, "reachGoal", "see broadcast programm click", {
      //     "see broadcast programm click": {
      //       ...this.$root.ymFields,
      //     },
      //   });
      // }
    },
    closePopup() {
      this.popup = false;
    },
    openIframe() {
      if (!localStorage.getItem("token") && !!!Object.keys(this.user).length) {
        if (!!!this.$route.query.access) {
          this.popup = true;
        } else {
          // this.openFrame = true;

          this.popup = true;
        }
        // this.openFrame = true;
      } else {
        this.openFrame = true;
      }
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "skv-event", {
          "skv-event": {
            "broadcast watch": {
              ...this.$root.ymFields,
            },
          },
        });
      }
    },
  },
  created() {
    this.timer();
    this.interval = setInterval(this.timer, 1000);
  },
  async mounted() {},
  beforeDestroy() {
    clearInterval(this.interval);
  },
  watch: {
    async timerResult() {
      if (!Object.keys(this.timerResult).length) {
        clearInterval(this.interval);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.first-banner {
  overflow: hidden;
  height: 400px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1220px) {
    flex-direction: column;
    align-items: stretch;
    height: auto;
  }
  &__content {
    margin-top: 40px;
  }

  &__title {
    display: none;
    color: #1f1f1f;
    font-family: "Roboto Slab", sans-serif;
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
    margin-bottom: 24px;

    @media screen and (min-width: 1220px) {
      color: #fff;
    }

    span {
      @media screen and (min-width: 1220px) {
        color: #ff7f32;
      }
    }

    @media screen and (max-width: 767px) {
      order: 2;
      display: block;
      font-size: 32px;
      line-height: 38px;
      margin-bottom: 24px;
      margin-top: 24px;
      span {
        @media screen and (min-width: 1220px) {
          color: #ff7f32;
        }
        // font-size: 21px;
        // line-height: 26px;
      }
    }
  }

  &__button {
    width: 200px;
    margin-top: auto;
    @media screen and (max-width: 767px) {
      order: 1;
      width: 100%;
    }
  }

  &__description {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 16px;
    color: #3c4242;
    @media screen and (min-width: 1220px) {
      color: #fff;
    }
    @media screen and (max-width: 767px) {
      order: 3;
    }
  }

  &__text {
    position: relative;
    z-index: 2;
    padding-left: 74px;
    max-width: 592px;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
    @media screen and (max-width: 1219px) {
      position: static;
      height: auto;
      margin-top: 16px;
      padding: 0;
    }

    @media screen and (max-width: 767px) {
      // background-color: #ccd7e0;
      padding: 24px 16px;
      display: flex;
      margin-top: 0;
      flex-direction: column;
    }
  }

  & picture {
    width: auto;
    height: 100%;

    @media screen and (max-width: 1219px) {
      width: 100%;
      height: auto;
    }
  }

  &__image {
    width: auto;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    @media screen and (max-width: 1219px) {
      width: 100%;
      height: auto;
      position: relative;
    }

    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
  }
}
.timer {
  &__value {
    margin-right: 16px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;

    @media screen and (max-width: 767px) {
      margin-right: 0;
      font-size: 32px;
      line-height: 38px;
    }
  }

  &__text {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;

    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
}
.event-detail {
  padding-top: 64px;
  @media screen and (max-width: 1220px) {
    padding-top: 40px;
  }

  &__banner {
    width: 100%;
  }

  &__timer {
    margin-bottom: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 24px 37px;
    background-image: url("https://storage.yandexcloud.net/" + $VUE_APP_BACKET_NAME + "/media/content/public/assets/img/skv-event/timer.jpg");
    background-size: cover;
    background-position: bottom right;
    color: #fff;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
      align-items: flex-start;
    }

    @media screen and (max-width: 767px) {
      padding: 16px;
    }

    &-title {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      margin-right: auto;

      @media screen and (max-width: 1220px) {
        margin-right: 0;
        margin-bottom: 32px;
      }

      @media screen and (max-width: 767px) {
        font-size: 21px;
        line-height: 26px;
      }
    }

    &-item {
      margin-right: 40px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      &:last-child {
        margin-right: 0;
      }

      @media screen and (max-width: 1220px) {
        margin-right: auto;
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
        margin-right: auto;
      }
    }

    &-row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @media screen and (max-width: 1220px) {
        width: 100%;
      }
    }

    &-date {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 42px;
      line-height: 48px;
      color: #ffffff;

      @media screen and (max-width: 767px) {
        font-size: 26px;
      }
    }
  }

  &__description-block {
    margin-bottom: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 48px;
    background-color: #f8f8f8;

    & img {
      height: 220px;
      @media screen and (max-width: 1220px) {
        width: 100%;
        height: auto;
      }

      @media screen and (max-width: 767px) {
        margin: 0 auto;
      }
    }

    @media screen and (max-width: 1220px) {
      display: block;
      padding: 24px;
    }

    @media screen and (max-width: 767px) {
      display: block;
      padding: 16px;
    }
  }

  &__iframe {
    position: relative;
    width: 100%;
    display: none;

    @media screen and (max-width: 1220px) {
      display: none;
    }
  }

  & iframe {
    margin-bottom: 48px;
    width: 100%;
    height: 800px;

    @media screen and (max-width: 1220px) {
      width: 100%;
      height: 600px;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      height: 400px;
    }
  }

  &__iframe_opened {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    padding: 64px;

    & iframe {
      height: 100%;
    }

    @media screen and (max-width: 1220px) {
      padding: 0;
    }
  }

  &__close-btn {
    cursor: pointer;
    position: absolute;
    top: 24px;
    left: 24px;
    z-index: 2;

    @media screen and (max-width: 1220px) {
      top: 0;
      right: 50%;
      transform: translateX(50%);
    }
  }

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__translation {
    max-width: 280px;
    // width: 189px;

    @media screen and (max-width: 1220px) {
      width: auto;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      max-width: none;
    }
  }

  &__date-info {
    border: 1px solid #ebefee;
    border-left: 4px solid #01d1ff;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 24px;
    margin-top: 64px;
    margin-bottom: 40px;
    background-color: #fff;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
      margin-top: 40px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 32px;
    }
  }

  &__about-title {
    margin-bottom: 24px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #3c4242;
  }

  &__about-description {
    margin-bottom: 32px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3C4242;
  }

  &__additional {
    margin-top: 32px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #656969;
  }

  &__date-text {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
    }
  }

  &__date-item {
    margin-right: 32px;

    @media screen and (max-width: 1220px) {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }

  &__date-item-title {
    margin-bottom: 4px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #b2b4b4;
  }

  &__date-item-value {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #3c4242;
  }

  &__add-calendar {
    width: 280px;
    margin-left: auto;
    @media screen and (max-width: 1220px) {
      width: 100%;
    }
  }

  &__usefull-materials {
    margin-top: 48px;
    &-title {
      font-family: "Roboto Slab", sans-serif;
      font-size: 23px;
      font-weight: 400;
      line-height: 29px;
      color: #1f1f1f;
      margin-bottom: 16px;
    }

    &-description {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      color: #3c4242;
      margin-bottom: 24px;
    }

    &-row {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      column-gap: 16px;

      @media screen and (max-width: 1220px) {
        overflow-x: auto;
      }
    }

    &-item {
      padding: 16px;
      border: 1px solid #d2d2d2;
      display: flex;
      flex-direction: column;

      &-theme {
        margin-bottom: 8px;
        font-family: "Roboto", sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        color: #830051;
      }

      &-title {
        margin-bottom: 16px;
        font-family: "Roboto Slab", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        color: #3c4242;
      }

      &-button {
        margin-top: auto;
      }
    }
  }
}

.slide-lector {
  // padding: 24px;
  border-radius: 16px;
  height: 100%;

  &__image-container {
    width: 280px;
    height: 270px;
    margin-bottom: 16px;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  &__tag {
    margin-bottom: 16px;
    border-radius: 8px;
    padding: 4px 8px;
    background-color: #1b98d5;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #fff;
    width: fit-content;
  }

  &__name {
    margin-bottom: 8px;
    color: #3c4242;
    font-family: "Roboto Slab", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  &__description {
    color: #b2b4b4;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
  }
}

.default-slider {
  padding: 24px 0 48px;
}
</style>

<style lang="scss">
.hobl {
  &__program {
    &-item {
      margin-bottom: 48px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      // background: rgba(0, 0, 0, 0.8);
      // padding: 24px;

      &:last-child {
        margin-bottom: 0;
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }

    &-time {
      width: fit-content;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #132647;
      padding: 4px 8px;
      border-radius: 99px;
      // background-color: #634075;
      background-color: #f8f8f8;
      margin-right: auto;

      @media screen and (max-width: 767px) {
        margin-bottom: 16px;
      }
    }

    &-info {
      width: 65%;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    &-title {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      // color: #ffffff;
      color: #3c4242;
    }

    &-description {
      margin-top: 8px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      // color: #b2b4b4;
      color: #656969;
    }
  }

  &-activities {
    &__title {
      margin-bottom: 24px;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      color: #1f1f1f;
    }

    &__description {
      margin-bottom: 24px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #3c4242;
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 32px;
      row-gap: 32px;

      @media screen and (max-width: 1219px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    &__item {
      padding: 40px 22px;
      background: #f8f8f8;
      border-radius: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

  &-activity {
    &__icon {
      height: 117px;
      margin-bottom: 32px;

      & img {
        height: 100%;
      }
    }

    &__button {
      width: 100%;
      border: 1px solid #2186af;
      color: #2186af;
      background-color: transparent;

      &:hover {
        border-color: #2eb5eb;
        color: #2eb5eb;
      }
    }
  }
}
.event-detail {
  i {
    font-style: italic !important;
  }

  b,
  strong {
    font-weight: 500 !important;
  }

  ul {
    list-style: disc;
    padding-left: 18px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3c4242;
  }

  ol {
    list-style: decimal;
    padding-left: 18px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3c4242;
  }

  li {
    margin-bottom: 8px;

    & > ul {
      margin-top: 8px;

      & li {
        color: #656969;
        list-style-type: none;
      }
    }
  }
}
</style>
